<template>
	<div class="page">
		<div class="top">
			<div class="titleBox">
				<div class="titTop">latest activity</div>
				<div class="titBottom">{{lang==6?'最新活动':'The latest activity'}}</div>
			</div>
			<div class="top-content">
				<div class="left" @click="$router.push(`/activity/details/${newList[0].id}?type=1`)"
					:style="`background-image:url(${newList[0].image})`" v-if="newList[0]">
					<div class="textBox">
						<p>{{newList[0].title}}</p>
						<p>{{newList[0].business_time + '-' + newList[0].end_time}}</p>
						<!-- <div class="ribbon">{{lang==6?'进行中':'underway'}}</div> -->
						<div :class="`ribbon ${newList[0].activity_status==1 ? '' : 'grayRibbn' }`">
							{{newList[0].activity_status == 1 ? (lang==6?'进行中':'ongoing') : (lang==6?'已结束':'end')}}
						</div>
					</div>
				</div>
				<div class="right">
					<div class="right-item" v-if="newList[1]"
						@click="$router.push(`/activity/details/${newList[1].id}?type=1`)"
						:style="`background-image:url(${newList[1].image})`">
						<div class="textBox">
							<p>{{newList[1].title}}</p>
							<p>{{newList[1].business_time + '-' + newList[1].end_time}}</p>
							<div :class="`ribbon ${newList[1].activity_status==1 ? '' : 'grayRibbn' }`">
								{{newList[1].activity_status == 1 ? (lang==6?'进行中':'ongoing') : (lang==6?'已结束':'end')}}
							</div>
						</div>
					</div>
					<div class="right-item" v-if="newList[2]"
						@click="$router.push(`/activity/details/${newList[2].id}?type=1`)"
						:style="`background-image:url(${newList[2].image})`">
						<div class="textBox">
							<p>{{newList[2].title}}</p>
							<p>{{newList[2].business_time}}</p>
							<div :class="`ribbon ${newList[2].activity_status==1 ? '' : 'grayRibbn' }`">
								{{newList[2].activity_status == 1 ? (lang==6?'进行中':'ongoing') : (lang==6?'已结束':'end')}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-pagination v-if="newtotal>0" background layout="prev, pager, next" :page-size="newlimit"
				:total="newtotal" :current-page="newpage" @current-change="newpage = $event;getNew()">
			</el-pagination>
		</div>
		<div class="bottom">
			<div class="title-flexBox">
				<div class="titleBox">
					<div class="titTop">Completed activities</div>
					<div class="titBottom">{{lang==6?'历史活动':'Historical events'}}</div>
				</div>
				<selectBox @change="page=1;getActivityList($event)"></selectBox>
			</div>
			<div class="bottom-content" ref="list">
				<div class="box" v-for="(group,index) in list" :key="index"
					:style="group.length==1 ? 'height:380px' : group.length==2 ? 'height:780px' : group.length==0 ? 'height:100px' : ''">
					<div class="item" v-for="(item,j) in group" :key="j" ref="item"
						@click="$router.push(`/activity/details/${item.id}?type=1`)"
						:style="`background-image:url(${item.image})`">
						<div class="textBox">
							<p>{{item.title}}</p>
							<p>{{item.business_time + ' - ' + item.end_time}}</p>
							<div :class="`ribbon ${item.activity_status==1 ? '' : 'grayRibbn' }`">
								{{item.activity_status == 1 ? (lang==6?'进行中':'ongoing') : (lang==6?'已结束':'end')}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-pagination background layout="prev, pager, next" :page-size="limit" :total="total" :current-page="page"
			@current-change="changePage">
		</el-pagination>
	</div>
</template>

<script>
	import selectBox from '../../components/selectBox.vue'
	import mixinLang from '../../components/mixin.js'
	export default {
		metaInfo: {
			title: '宝爱捷(中国)汽车投资有限公司', // set a title
			meta: [{ // set meta
					name: 'keyWords',
					content: '宝马活动，奥迪活动，保时捷活动，宾利活动，兰博基尼活动，mini活动，盛大开幕，豪车活动，品牌活动，集团活动，宝爱捷活动，豪车party'
				},
				{
					name: 'description',
					content: ''
				}
			]
		},
		mixins: [mixinLang],
		components: {
			selectBox
		},
		data() {
			return {
				page: 1,
				limit: 4,
				total: 1,
				newList: [],
				list: [],
				newpage: 1,
				newlimit: 3,
				newtotal: 1,
			}
		},
		methods: {
			changePage(page) {
				this.page = page
				this.getActivityList()
			},
			getInfo() {
				this.getNew()
				this.getActivityList()
			},
			getNew() {
				// 最新的三条
				this.$api.getActivityList({
					cate_no: '21434c45',
					form: 'new'
				}, {
					page: this.newpage,
					limit: this.newlimit
				}).then(res => {
					this.newList = res.list
					this.newtotal = Number(res.paginated.count)
				})
			},
			getActivityList(e = {}) {
				// 旧数据
				this.$api.getActivityList({
					cate_no: '21434c45',
					form: 'history',
					city_id: e.c ? e.c : '',
					business_time: e.d ? e.d : ''
				}, {
					page: this.page,
					limit: this.limit
				}).then(res => {
					this.list = [res.list]
					this.total = Number(res.paginated.count)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		background-color: #101621;
		padding: 150px 0 50px;
		position: relative;

		>div {
			padding: 50px 100px;
		}
	}

	.titleBox {
		margin-bottom: 60px;

		.titBottom {
			color: #FFF;
		}
	}

	.top {
		.top-content {
			display: flex;
			color: #FFF;
			justify-content: space-between;
			margin-bottom: 80px;

			.left,
			.right-item {
				position: relative;

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 20px;
					left: 20px;
					width: calc(100% - 40px);
					height: calc(100% - 40px);
					border: 2px solid #FFF;
				}
			}

			.left {
				width: 840px;
				height: 780px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-image: url(../../assets/brandStory/introduce/bg.png);
				position: relative;
				cursor: pointer;
			}

			.right {
				.right-item {
					width: 840px;
					height: 380px;
					background-size: cover;
					position: relative;
					cursor: pointer;

					&:nth-child(1) {
						margin-bottom: calc(780px - (380px * 2));
					}
				}
			}
		}
	}

	.textBox {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 120px;
		background: rgba(24, 24, 24, 0.85);
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 18px;
		font-weight: 350;
		padding: 0 50px;
		color: #FFF;

		p {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		p:nth-child(1) {
			font-family: SourceHanSansCN-Medium, sans-serif;
			font-size: 24px;
			font-weight: 500;
			margin: 20px 0 10px 0;
		}

		.ribbon {
			position: absolute;
			width: 150px;
			height: 32px;
			background: url(../../assets/activity/ribbon-red.png) no-repeat top/100% 100%;
			color: #FFF;
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 18px;
			// font-weight: bold;
			right: 0;
			bottom: 20px;
			text-align: center;
			line-height: 32px;
			z-index: 999;
			text-transform: uppercase;
		}

		.grayRibbn {
			background: url(../../assets/activity/ribbon-gray.png) no-repeat top/100% 100%;
		}
	}

	.bottom {
		margin-top: 60px;

		.title-flexBox {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.bottom-content {
			position: relative;
			width: 100%;

			.box {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				position: relative;
				height: 1310px;
				cursor: pointer;

				.item {
					position: absolute;
					height: 380px;
					width: 840px;
					// background-image: url(../../assets/brandStory/introduce/bg.png);
					background-size: cover;
					margin-bottom: 30px;

					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 20px;
						left: 20px;
						width: calc(100% - 40px);
						height: calc(100% - 40px);
						border: 2px solid #FFF;
					}

					&:nth-child(odd) {
						left: 0;
					}

					&:nth-child(even) {
						right: 0;
					}

					&:nth-child(1) {
						height: 380px !important;
						top: 0;
					}

					&:nth-child(2) {
						height: 780px;
						top: 0;
					}

					&:nth-child(3) {
						height: 780px;
						top: 410px;
					}

					&:nth-child(4) {
						top: 810px;
					}
				}
			}


		}
	}

	.el-pagination {
		text-align: center;
		padding-top: 0 !important;

		/deep/ button,
		/deep/ .number {
			background-color: transparent !important;
			border: 1px solid #B6B6B6 !important;
			border-radius: 4px !important;
		}

		/deep/ .active {
			background-color: #97233F !important;
			border-color: #97233F !important;
		}

		/deep/ .number:hover {
			color: #97233F !important;
		}
	}
</style>