<template>
	<div class="selectBox">
		<!-- <el-select clearable v-model="province" :popper-append-to-body="false"
			:placeholder="lang==6?'请选择省份':'Please select a province'">
			<el-option v-for="(item,index) in provinceList" :key="item.id" :label="item.name" :value="item.id">
			</el-option>
		</el-select>
		<el-select clearable v-model="city" :popper-append-to-body="false"
			:placeholder="lang==6?'请选择城市':'Please select a city'">
			<el-option v-for="(item,index) in cityList" :key="item.id" :label="item.name" :value="item.id">
			</el-option>
		</el-select> -->
		<div class="right">
			<el-select clearable v-model="sort" :popper-append-to-body="false" :placeholder="lang==6?'选择排序方式':'Sort'">
				<el-option v-for="(item,index) in sortList" :key="item.id" :label="lang==6?item.title:item.titleEn"
					:value="item.id">
				</el-option>
			</el-select>
			<!-- <el-date-picker clearable v-model="time" type="date" value-format="yyyy-MM-dd"
				:placeholder="lang==6?'选择排序方式':'Selection sort'">
			</el-date-picker> -->
		</div>
	</div>
</template>

<script>
	import mixinLang from './mixin.js'
	export default {
		name: 'selectBox',
		mixins: [mixinLang],
		props: {
			search_type: '',
			brandId: ''
		},
		data() {
			return {
				time: '',
				province: '',
				provinceList: [],
				city: '',
				cityList: [],
				sort: '',
				sortList: [{
					title: '时间正序',
					titleEn: 'From Far to Near',
					id: 'asc'
				}, {
					title: '时间倒序',
					titleEn: 'From Near to Far',
					id: 'desc'
				}]
			}
		},
		watch: {
			province: function(a, b) {
				this.city = ''
				this.cityList = []
				this.$api.getCityList({
					pid: this.province,
					search_type: this.search_type,
					brand_id: this.brandId
				}).then(res => {
					this.cityList = res.list
					this.city = ''
				})
			},
			city: function(a, b) {
				this.$emit('change', {
					p: this.province,
					c: this.city,
					d: this.time
				})
			},
			sort: function(e) {
				this.$emit('change', {
					p: this.province,
					c: this.city,
					s: this.sort
				})
			}
		},
		methods: {
			getInfo() {
				this.getProvinceList()
			},
			getProvinceList() {
				this.$api.getProvinceList({
					search_type: this.search_type,
					brand_id: this.brandId
				}).then(res => {
					this.provinceList = res.list
				})
			},
			changeP(e) {
				this.city = ''
				this.cityList = []
				this.$api.getCityList({
					pid: this.province,
					search_type: this.search_type,
					brand_id: this.brandId
				}).then(res => {
					this.cityList = res.list
					this.city = ''
				})
			},
			changeC(e) {
				this.$emit('change', {
					p: this.province,
					c: this.city,
					d: this.time
				})
			},
			changeD(e) {
				this.$emit('change', {
					p: this.province,
					c: this.city,
					d: this.time
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.selectBox {
		color: #3D3D3D;
		// width: 700px;
		height: 46px;
		border-bottom: 1px solid #999999;
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px;
		font-weight: normal;
		display: flex;
		align-items: flex-end;
		// padding-bottom: 25px;
	}

	.el-select {
		// width: 300px;
		border: none;
		margin-top: 20px;
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px;
		font-weight: normal;
		color: #666666;
	}

	/deep/.el-input__inner {
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
		border: none;
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px;
		font-weight: normal;
		color: #666666;

		&::placeholder {
			color: #666666;
		}
	}

	/deep/.el-input__icon {
		font-size: 20px;
		color: #666666;
	}

	/deep/ .el-select-dropdown__item {
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px !important;
		font-weight: normal;
		color: #666666;
		height: 60px !important;
		line-height: 60px;
	}

	.right {
		// margin-left: 203px;
		display: flex;
		align-items: center;
		width: 450px;

		img {
			width: 56px;
			margin-left: 20px;
		}

		/deep/ .el-icon-date {
			color: #97233F;
			margin-right: 20px;
		}

		/deep/.el-input__inner {
			margin-left: 30px;
		}

		/deep/.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 100% !important;
		}
	}
</style>